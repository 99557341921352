import React, { Component } from 'react';

/* THIS PAGE HAS BEEN MOVED TO NOTIFICATIONS/TOAST */
export default class GuidelinesIndex extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.___navigate('/components/notifications/toast/design');
  }

  render() {
    return <div />;
  }
}
